<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title class="px-6 pt-6">
      <v-row class="ma-2" align="center">
        <v-text-field v-model="filter" autofocus variant="outlined" bg-color="white" hide-details density="compact"
                      :placeholder="$t('common.search-placeholder', {component: componentLabel.toLowerCase()})"
                      :label="$t('common.search-label', {component: componentLabel.toLowerCase()})"/>
        <v-spacer/>
        <ButtonWithTooltip :icon="view === 'grid' ? 'mdi-view-grid' : 'mdi-view-grid-outline'" color="primary"
                           @clicked="view = 'grid'" :tooltip-text="$t('common.display-as-grid')"/>
        <ButtonWithTooltip :icon="view === 'list' ? 'mdi-view-list' : 'mdi-view-list-outline'" color="primary"
                           @clicked="view = 'list'" :tooltip-text="$t('common.display-as-list')"/>
        <ButtonWithTooltip v-if="enableTags" :icon="view === 'tags' ? 'mdi-tag-multiple' : 'mdi-tag-multiple-outline'"
                           color="primary" @clicked="view = 'tags'" :tooltip-text="$t('common.display-as-tags')"/>
        <v-divider v-if="allowCreation" vertical class="ml-1 mr-5"/>
        <v-btn v-if="allowCreation" color="primary" @click="createComponent">
          <v-icon class="mr-2">mdi-plus-thick</v-icon>
          <span>{{ createComponentLabel }}</span>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text class="my-6">
      <div v-if="components.length">
        <v-expansion-panels v-if="view === 'tags'" class="mt-4" v-model="panel">
          <v-expansion-panel v-for="[key, value] of Object.entries(groupByTags())" :value="key" class="mb-4">
            <v-expansion-panel-title>
            <span class="text-h6 font-weight-medium">
              {{ key }} ({{ value.length }})
            </span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row class="pa-2">
                <v-col cols="6" v-for="component in value" style="cursor: pointer" class='pa-2'
                       @click="navigateToComponent(component.code)">
                  <ComponentItem :component="component"/>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row v-else>
          <v-col :cols="view === 'grid' ? 6 : 12" v-for="component in filteredComponentsList" style="cursor: pointer"
                 @click="navigateToComponent(component.code)" :class="view === 'grid' ? 'px-4 py-2' : 'px-4 pa-1'">
            <ComponentItem :component="component"/>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-alert type="info" prominent variant="outlined">
          <span class="text-subtitle-1 mb-6 mt-3"
                v-html="$t('common.components-has-no-items', {label: componentLabel.toLowerCase()})"/>
        </v-alert>
      </div>
    </v-card-text>
  </v-card>
</template>
<script setup>
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import ComponentItem from "@/components/common/ComponentItem.vue";
import {computed, ref} from "vue";

let props = defineProps({
  components: {
    type: Array,
    defaultValue: []
  },
  allowCreation: true,
  componentLabel: null,
  createComponentLabel: null,
  searchComponentLabel: null,
  enableTags: false
})

let filter = ref('')
let panel = ref('')
let view = ref('grid')

let filteredComponentsList = computed(() => {
  return props.components
    .filter(component => component.code.toLowerCase().includes(filter.value.toLowerCase())
      || component.label.toLowerCase().includes(filter.value.toLowerCase())
    )
    .sort((a, b) => a.code.localeCompare(b.code))
})

let emit = defineEmits(['create', 'navigate'])

function createComponent() {
  emit('create')
}

function navigateToComponent(code) {
  emit('navigate', code)
}

function groupByTags() {
  if (!props.enableTags) {
    return {}
  }
  let tags = Array.from(new Set(filteredComponentsList.value.map(component => component.tags).flat()));
  let groups = {}
  tags.forEach(tag => groups[tag] = [])
  filteredComponentsList.value.forEach(component => {
    component.tags.forEach(tag => groups[tag].push(component))
  })
  return groups
}
</script>