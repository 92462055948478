<template>
    <v-hover v-slot="{ isHovering, props }">
        <v-card :onclick="onClick" :elevation="isHovering ? up ? 12 : 4 : 2" v-bind="props" hover
                :style="up ? '' : 'opacity: 0.6; cursor: not-allowed'">
            <v-card-text>
                <v-row align="center">
                    <v-col cols="5" class="d-flex flex-column align-center my-auto mt-2">
                        <img v-if="code" :src="'/' + code + '-color.png'" :alt="moduleName" width="128">
                        <span class="mt-6 text-h4 font-weight-medium">{{ moduleName }}</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col style="height: 200px" class="d-flex flex-column justify-space-between ml-2 pb-0">
                        <v-sheet class="text-h5">{{ desc }}</v-sheet>
                        <v-sheet>
                            <v-row>
                                <v-col>
                                    <v-alert v-if="loading" density="compact" color="warning"
                                             icon="mdi-timer-sand">
                                        <div class="d-flex flex-row align-center justify-space-between">
                                            <span class="text-body-1">
                                                {{ $t('common.checking-state') }}
                                            </span>
                                            <hollow-dots-spinner
                                                :animation-duration="1000"
                                                :dot-size="6"
                                                :dots-num="3"
                                                color="white"
                                            />
                                        </div>
                                    </v-alert>
                                    <div v-else>
                                        <v-alert v-if="up" density="compact" color="success"
                                                 icon="mdi-arrow-up-bold-circle">
                                            <span v-html="status" class="text-body-1"></span>
                                        </v-alert>
                                        <v-alert v-else density="compact" color="error"
                                                 icon="mdi-arrow-down-bold-circle">
                                            <span class="text-body-1">{{ $t('common.unreachable') }}</span>
                                        </v-alert>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-hover>
</template>
<script setup>
import {HollowDotsSpinner} from 'epic-spinners'
import {useApi} from "@/composables/useApi";
import {computed, onMounted, ref} from "vue";
import {useRouter} from "vue-router";

const props = defineProps({
  code: null,
  desc: null
})

let status = ref("")
let up = ref(false)
let loading = ref(true)

let moduleName = computed(() => props.code[0].toUpperCase() + props.code.slice(1))

let api = useApi();
onMounted(() => {
  api.$support.getState(props.code)
    .then(state => {
      up.value = true
      status.value = Array.from(Object.entries(state))
        .map(([k, v]) => k + ': <strong>' + v + '</strong>')
        .join(', ')
    })
    .finally(() => loading.value = false)
})

let router = useRouter()
function onClick() {
  if (up.value) {
    router.push({name: props.code})
  }
}

</script>