<template>
  <v-app v-if="getCurrentModule()">
    <div>
      <v-app-bar color="primary">
        <v-app-bar-nav-icon variant="text" @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-app-bar-title>
          <v-row class="pa-0 ma-0">
            <img :src="'/' + getCurrentModule() + '-color.png'" alt="catalog" width="32">
            <span class="font-weight-medium ml-2">
              RuleShake <span class="font-weight-light">{{ moduleName }}</span>
            </span>
          </v-row>
        </v-app-bar-title>
        <v-spacer/>
        <Docs/>
        <div style="width: 100px">
          <v-select v-model="$i18n.locale" :items="$i18n.availableLocales" variant="solo" density="comfortable"
                    class="mt-5 mx-2" bg-color="primary"/>
        </div>
        <v-divider vertical class="mx-2"/>
        <UserMenu v-if="isAuthenticated"/>
      </v-app-bar>
      <v-navigation-drawer v-if="isAuthenticated" permanent :rail="drawer">
        <v-list density="compact">
          <v-list-item v-for="item in menus" :prepend-icon="item.icon" :title="$t(item.label)" :value="item.link"
                       @click="navigate(item)" :active="isCurrentModule(item)"/>
        </v-list>
      </v-navigation-drawer>
    </div>
    <v-main>
      <v-container style="max-width: 1440px">
        <router-view :key="$route.fullPath"/>
      </v-container>
    </v-main>
    <v-footer app absolute color="secondary">
      <v-row justify="center" no-gutters>
        <ButtonWithTooltip v-if="isAuthenticated" class="mx-2" v-for="item in menus" :icon="item.icon"
                           :tooltip-text="$t(item.label)" @clicked="navigate(item)"/>
        <v-col cols="12" class="text-center">
          <span>{{ new Date().getFullYear() }} — <strong>RuleShake</strong></span>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
  <ConfirmDialog ref="confirm"></ConfirmDialog>
  <DialogsWrapper/>
</template>

<script setup>
import {useFavicon, useTitle} from '@vueuse/core'
import {useRoute, useRouter} from "vue-router";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import {computed, ref, watch} from "vue";
import UserMenu from "@/components/common/UserMenu.vue";
import Docs from "@/components/common/Docs.vue";
import {useDisplay} from "vuetify";
import {useAuthStore} from "@/store/auth";

let menus = ref([
  {
    label: 'menu.studio-home',
    link: 'home',
    icon: 'mdi-pencil-ruler'
  },
  {
    label: 'menu.catalog',
    link: 'catalog',
    icon: 'mdi-notebook-outline'
  },
  {
    label: 'menu.referential',
    link: 'referential',
    icon: 'mdi-layers-triple-outline'
  },
  {
    label: 'menu.runner',
    link: 'runner',
    icon: 'mdi-turbine'
  },
  // {
  //   label: 'menu.batch',
  //   link: 'batch',
  //   icon: 'mdi-chart-donut-variant'
  // },
  // {
  //   label: 'menu.contents',
  //   link: 'contents',
  //   icon: 'mdi-tangram'
  // },
  {
    label: 'menu.api',
    link: 'api',
    icon: 'mdi-api'
  },
  {
    label: 'menu.about',
    link: 'about',
    icon: 'mdi-information-outline'
  }
])

const route = useRoute();
let authStore = useAuthStore();
const moduleName = computed(() => {
  let name = route.meta.module;
  if (!name) {
    return ""
  }
  return name[0].toUpperCase() + name.slice(1)
})

const icon = useFavicon()
const title = useTitle()
watch(
  () => route.fullPath,
  async () => {
    if (route.meta.module) {
      icon.value = '/' + route.meta.module + '-color.png';
      title.value = 'RuleShake ' + moduleName.value
    }
  }
);

const isAuthenticated = computed(() => authStore.isLoggedIn);
const isLoading = ref(false);

let router = useRouter();

const {lgAndDown} = useDisplay();
const drawer = ref(lgAndDown.value)

function navigate(item) {
  router.push({name: item.link})
}

function isCurrentModule(item) {
  if (item && route.name) {
    return item.link === getCurrentModule();
  }
  return false
}

function getCurrentModule() {
  return route.meta.module;
}
</script>
