<template>
  <v-card-text>
    <v-card-title class="my-4 border border-opacity-50 border rounded">
      <v-label class="pl-2">{{ $t('common.filter') }}</v-label>
      <v-row align="center" class="pa-2">
        <v-col v-for="property in filterableProperties" :cols="filterablePropertyColSize">
          <v-autocomplete v-model="filter[property.code]" :label="property.label" :items="values[property.code]"
                          variant="outlined" hide-details bg-color="white" clearable/>
        </v-col>
      </v-row>
    </v-card-title>
    <div class="pb-4">
      <v-row class="border my-1 pa-2 bg-primary" dense>
        <v-col @click="doSort('code')" class="d-flex flex-row" style="cursor: pointer">
          <span class="text-subtitle-1 font-weight-bold" style="user-select: none">Code</span>
          <div v-if="pagination.sortField === 'code'">
            <v-icon v-if="pagination.sortDirection === 'ASC'">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </div>
        </v-col>
        <v-col v-for="property in properties" @click="doSort(property.code)" class="d-flex flex-row"
               style="cursor: pointer">
          <span class="text-subtitle-1 font-weight-bold" style="user-select: none">{{ property.label }}</span>
          <div v-if="pagination.sortField === property.code">
            <v-icon v-if="pagination.sortDirection === 'ASC'">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </div>
        </v-col>
        <v-col>
        </v-col>
      </v-row>
      <div v-for="element in records">
        <v-row align="center" style="cursor: pointer" @click="select(element)" dense
               :class="isSelected(element) ? 'border pa-2 bg-green-lighten-4' : 'border pa-2 bg-grey-lighten-5'">
          <v-col :class="element.disabled ? 'text-blue-grey-lighten-2' : ''">
            <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{
                element.code
              }}</strong>
          </v-col>
          <v-col v-for="property in properties" :class="element.disabled ? 'text-blue-grey-lighten-2' : ''">
            <span v-if="element.properties[property.code]" class="text-subtitle-1 font-weight-regular font-italic">
              {{ element.properties[property.code].value }}
            </span>
          </v-col>
          <v-col class="d-flex flex-row justify-end align-center">
            <v-chip v-if="element.disabled" color="warning" class="mr-2" label>
              <span style="font-family: monospace, serif">{{ $t('referential.dataset.records.disabled') }}</span>
            </v-chip>
          </v-col>
        </v-row>
      </div>
      <v-row align="center" class="mt-4 ml-1">
        <span class="text-subtitle-1"
              v-html="$t('referential.records.page', {nb: numberOfElements, total: totalElements})"/>
        <v-spacer/>
        <v-pagination v-model="pagination.page" active-color="primary" variant="text" total-visible="5"
                      :length="totalPages"/>
      </v-row>
    </div>
  </v-card-text>
  <Loading :loading="loading" :text="$t('referential.records.loading', {code: datasetCode})"/>
</template>
<script setup>
import Loading from "@/components/common/Loading.vue";
import {computed, onUnmounted, reactive, ref, watch} from "vue";
import {useReferentialApi} from "@/composables/useReferentialApi";

const props = defineProps(['datasetCode'])

let loading = ref(false)
let dataset = ref({})
let records = ref([])
let values = ref({})
let filter = reactive({})
let pagination = reactive({
  page: 1,
  sortField: null,
  sortDirection: 'ASC',
  size: 10
})
let totalPages = ref(null)
let totalElements = ref(null)
let numberOfElements = ref(null)
let selected = ref(null)

let referentialApi = useReferentialApi()

referentialApi.$dataset.find(props.datasetCode)
  .then(result => {
    dataset.value = result
    search()
  })

onUnmounted(() => {
  dataset.value = null
  selected.value = null
  records.value = []
  values.value = {}
  filter.value = {}
})

const emit = defineEmits(['filter', 'selected', 'totalElements'])

watch(filter, (val) => {
  pagination.page = 1
  emit("filter", val);
  search()
})

watch(pagination, () => search())

let properties = computed(() => {
  if (dataset.value && dataset.value.properties) {
    return dataset.value.properties
      .filter(v => v.showInTable)
      .sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
  }
  return []
})

let filterableProperties = computed(() => {
  if (dataset.value && dataset.value.properties) {
    return dataset.value.properties
      .filter(v => v.showInFilter)
      .sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
  }
  return []
})

let filterablePropertyColSize = computed(() => Math.max(3, 12 / filterableProperties.value.length))

function select(record) {
  selected.value = record
  emit("selected", record);
}

function search() {
  loading.value = true
  const newFilter = {}
  Object.assign(newFilter, filter, pagination)
  referentialApi.$dataset.searchRecords(dataset.value.code, newFilter)
    .then(result => {
      records.value = result.content
      totalPages.value = result.totalPages
      totalElements.value = result.totalElements
      numberOfElements.value = result.numberOfElements
      emit("totalElements", totalElements.value);
      referentialApi.$dataset.propertiesValues(dataset.value.code, newFilter)
        .then(result2 => {
          values.value = result2
          loading.value = false
        })
    })
}

function isSelected(record) {
  if (record && selected.value) {
    return record.code === selected.value.code
  }
  return false
}

function doSort(property) {
  const currentSortField = pagination.sortField
  const currentSortDirection = pagination.sortDirection
  if (currentSortField === property) {
    if (currentSortDirection === 'ASC') {
      pagination.sortDirection = 'DESC'
    } else {
      pagination.sortDirection = 'ASC'
    }
  } else {
    pagination.sortDirection = 'ASC'
  }
  pagination.sortField = property
}
</script>