import BaseApi from "@/services/api/BaseApi";

export default class VariableApi extends BaseApi {

    constructor(accessToken) {
        super(accessToken);
    }

    listCollectionVariables(collectionCode) {
        return this.call(`/catalog-api/v1/collections/${collectionCode}/variables`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.variable.actions.list-collection-variables',
                params: {
                    code: collectionCode
                }
            }
        })
    }

    addVariableToCollection(collectionCode, variable) {
        return this.call(`/catalog-api/v1/collections/${collectionCode}/variables`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(variable),
            action: {
                key: 'catalog.variable.actions.add-variable-to-collection',
                params: {
                    code: collectionCode
                }
            }
        })
    }

    addVariableToComposite(compositeRef, variable) {
        return this.call(`/catalog-api/v1/variables/${encodeURIComponent(compositeRef)}/sub-variables`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(variable),
            action: {
                key: 'catalog.variable.actions.add-variable-to-composite',
                params: {
                    code: compositeRef
                }
            }
        })
    }

    saveNewSubVariablesOrder(variableReference, newOrder) {
        return this.call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/sub-variables-order`, {
            responseFormat: 'ignore',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(Object.fromEntries(newOrder)),
            action: {
                key: 'catalog.variable.actions.save-variables-new-order',
                params: {
                    code: variableReference
                }
            }
        })
    }

    find(variableReference) {
        return this.call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.variable.actions.load-variable',
                params: {
                    code: variableReference
                }
            }
        })
    }

    listCompositeVariables(variableReference) {
        return this.call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/sub-variables`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.variable.actions.list-composite-sub-variables',
                params: {
                    code: variableReference
                }
            }
        })
    }

    getAvailableLoopingVariablesReferences(variableReference) {
        const collectionCode = variableReference.substring(0, variableReference.indexOf('/'))
        return this.call(`/catalog-api/v1/collections/${collectionCode}/variables-names?multiple=true`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.variable.actions.list-available-looping-variables',
                params: {
                    code: variableReference
                }
            }
        })
            .then(result => result.filter(e => !e.startsWith(variableReference)).map(e => e.substring(e.indexOf('/') + 1)))
    }

    deleteVariable(variableReference) {
        return this.call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'catalog.variable.actions.delete-variable',
                params: {
                    code: variableReference
                }
            }
        })
    }

    updateVariable(variable) {
        let variableReference = `${variable.reference.parent}/${variable.reference.code}`;
        return this.call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(variable),
            action: {
                key: 'catalog.variable.actions.update-variable',
                params: {
                    code: variableReference
                }
            }
        })
    }

    duplicateVariable(variableReference, duplicateInfos) {
        return this.call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/duplicate`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(duplicateInfos),
            action: {
                key: 'catalog.variable.actions.duplicate-variable',
                params: {
                    code: variableReference
                }
            }
        })
    }

    getCompositesVariable(collectionCode) {
        return this.call(`/catalog-api/v1/collections/${collectionCode}/variables-names?type=COMPOSITE`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.variable.actions.list-composite-variables',
                params: {
                    code: collectionCode
                }
            }
        })
    }

    addProperty(variableReference, property) {
        return this.call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/properties`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(property),
            action: {
                key: 'catalog.variable.actions.add-property',
                params: {
                    code: variableReference
                }
            }
        })
    }

    updateProperty(variableReference, property) {
        return this.call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/properties/${property.name}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(property),
            action: {
                key: 'catalog.variable.actions.update-property',
                params: {
                    code: property.name
                }
            }
        })
    }

    deleteProperty(variableReference, property) {
        return this.call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/properties/${property.name}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'catalog.variable.actions.delete-property',
                params: {
                    code: property.name
                }
            }
        })
    }

}